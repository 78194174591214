import {COOKIE_KEYS} from "@/constants/cookie";
import {useConfigStore} from "@/plugins/API/config";

export const useApp = () => {
    const onAppLoaded = async () => {
        const route = useRoute()

        // Get Reward code from URL and store in cookie
        const {rcode = ''} = route.query
        const rewardCode = useCookie(COOKIE_KEYS.REWARD_CODE)
        if (rcode) rewardCode.value = rcode

        const configStore = useConfigStore()

        setTimeout(() => {
            configStore.setAppLoaded(true)
        }, 1500)
    }

    return {
        onAppLoaded
    }
}
import {getFormattedWebsitePath, getUrlWithoutDomain} from '@/utilities';
import useCustomConfig from "@/composables/useEnvConfig";
import {useRouteMatcher} from "~/composables/useRouteMatcher";
import {PAGES_NAMES} from "@/constants/pages";

export const useSeoMeta = () => {

    const config = useCustomConfig()
    const isProd = config.public.APP_ENV === 'production'
    const websiteUrl = config.public.WEBSITE_URL

    const i18nHead = useLocaleHead({
        addSeoAttributes: {
            canonicalQueries: ['q', 'page', 'filters', 'manufacturers', 'sort']
        }
    })

    const {t} = useI18n()

    const {isRouteMatched} = useRouteMatcher();

    const getCommonMeta = () => {
        const notAllowedRoutes = [PAGES_NAMES.CATEGORY, PAGES_NAMES.PRODUCT];

        if (isRouteMatched(notAllowedRoutes)) return []

        return [
            {
                name: 'description',
                content: t('seo.description')
            },
            {
                name: 'og:title',
                content: 'نايس ون'
            },
            {
                name: 'og:type',
                content: 'product'
            },
            {
                name: 'og:url',
                content: getFormattedWebsitePath('/')
            },
            {
                name: 'og:email',
                content: 'cs@niceonesa.com'
            },
            {
                name: 'og:phone_number',
                content: '0092 0033 385'
            },
            {
                name: 'og:site_name',
                content: 'نايس ون'
            },
        ];
    };

    const getRobotsMeta = ({notFound = false} = {}) => {

        const noIndex = 'noindex,nofollow';
        const index = 'index,follow';

        const robotsShouldIndexedRoutes = [PAGES_NAMES.HOME, PAGES_NAMES.BRANDS, PAGES_NAMES.BLOGS, PAGES_NAMES.BLOG_DETAILS, PAGES_NAMES.CATEGORY, PAGES_NAMES.PRODUCT]

        if (!isRouteMatched(robotsShouldIndexedRoutes) || notFound) return []

        return [
            {name: 'robots', content: () => isProd ? index : noIndex}
        ]
    };

    const getLinks = ({notFound = false} = {}) => {
        const allowedRoutes = [PAGES_NAMES.HOME, PAGES_NAMES.CATEGORY, PAGES_NAMES.BRANDS];

        if (!isRouteMatched(allowedRoutes) || notFound) return []

        return i18nHead.value?.link || [];
    };

    const getProductLinks = ({link = 'https://test.com/123', notFound = false} = {}) => {

        if (notFound) return []

        // remove trailing slash
        const formattedLink = link?.replace(/\/$/, '') || ''

        // Remove the domain from url and replace trailing slash
        const relativeLink = getUrlWithoutDomain(formattedLink, true);

        let links = [
            {
                rel: "alternate",
                href: formattedLink,
                hreflang: "x-default"
            },
            {
                rel: "canonical",
                href: formattedLink
            }
        ];

        ['ar', 'en'].forEach(lang => {
            links.unshift({
                rel: "alternate",
                href: `${websiteUrl}/${lang}${relativeLink}`,
                hreflang: lang
            });
        });

        return links
    }

    return {
        getCommonMeta,
        getRobotsMeta,
        getLinks,
        getProductLinks
    };
}

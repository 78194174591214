export const useRouteMatcher = () => {

    const route = useRoute();

    const isRouteMatched = (routes = []) => {
        const routeName = route.name;

        if (!routeName) return false;

        const baseRouteName = routeName.split('_')[0];
        return routes.includes(baseRouteName) || false;
    };

    return {
        isRouteMatched,
    };
}
<template>
  <div class="flex items-center gap-1 h-[48px] text-black text-base">
    <span><niceone-image :alt="activeLang" :src="$publicPath('/images/icon.svg')" width="18"/></span>
    <span class="uppercase hidden sm:block text-sm">{{ activeLang }}</span>
    <span><van-icon name="arrow-down" size="16"></van-icon></span>
  </div>
  <ul class="me-2 ltr:right-0 rtl:left-0 absolute bg-dark-grey shadow-cus p-[25px] top-[100%] w-[371px] z-[3] border-t-2 border-black rounded-b-md menu-niceone">
    <li>
      <p class="text-xs mb-5 font-semibold text-txt-secondary">{{ $t('menu.languages') }}</p>
    </li>
    <li>
      <van-radio-group
          v-model="activeLang"
          class="flex justify-between items-center mb-3"
          direction="horizontal"
      >
        <van-radio
            v-for="(item, index) in languages"
            :key="index"
            :name="item.code"
            @click="changeLanguage(item.code)">
            <span class="flex items-center gap-2">
              <niceone-image v-if="item.code ==='en'" :alt="item.title" :src="$publicPath('/images/oval-3@3x.png')"
                             class="w-5"/>
              <niceone-image v-if="item.code ==='ar'" :alt="item.title" :src="$publicPath('/images/oval-2@3x.png')"
                             class="w-5"/>
              <span>{{ item.title }}</span>
            </span>
        </van-radio>
      </van-radio-group>
    </li>
  </ul>
</template>

<script setup>
import {LANGUAGES as languages} from "@/constants";
import {track} from "@/composables/useTrackEvent";
import {TRACKERS} from "@/constants/events";

const {locale} = useI18n();
const activeLang = ref(locale.value);

const i18nHead = useLocaleHead({
  addSeoAttributes: {
    canonicalQueries: ['q', 'page', 'filters', 'manufacturers', 'sort']
  }
})

const changeLanguage = (code) => {

  if (locale.value === code) return

  track(TRACKERS.NAVIGATION_MENU, {
    eventLabel: 'Language',
    eventAction: code === 'ar' ? 'Arabic' : 'English'
  });
  activeLang.value = code;
  window.location.href = i18nHead.value?.link?.find(item => item.hreflang === code)?.href
};
</script>


<style lang="scss" scoped></style>

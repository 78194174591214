<template>
  <van-popup v-model:show="isCookieBannerShown" :lock-scroll="false" :overlay="false" class="p-3 md:p-6 shadow-lg"
             position="bottom">
    <div class="container mx-auto">
      <div class="flex flex-col md:flex-row items-center gap-3 md:gap-24">
        <div class="flex flex-col gap-3">
          <div class="font-niceone-medium text-lg">{{ $t('cookie_consent_popup.title') }}</div>
          <device-wrapper #default="{ desktop }">
            <template v-if="desktop">
              <div>{{ $t('cookie_consent_popup.description') }}</div>
              <div class="text-boulder text-sm">
                <i18n-t keypath="cookie_consent_popup.terms">
                  <template #privacy_policy>
                    <nuxt-link :to="$routerUrl('privacy')" class="text-cerulean hover:underline">
                      {{ $t('cookie_consent_popup.terms_policy') }}
                    </nuxt-link>
                  </template>
                </i18n-t>
              </div>
            </template>
            <template v-else>
              <template v-if="showMore">
                <div>{{ $t('cookie_consent_popup.description') }}</div>
                <div class="text-boulder text-sm">
                  <i18n-t keypath="cookie_consent_popup.terms">
                    <template #privacy_policy>
                      <nuxt-link :to="$routerUrl('privacy')" class="text-cerulean hover:underline">
                        {{ $t('cookie_consent_popup.terms_policy') }}
                      </nuxt-link>
                    </template>
                  </i18n-t>
                </div>
              </template>
              <div v-else class="van-multi-ellipsis--l2">{{ $t('cookie_consent_popup.description') }}</div>
              <div class="text-secondary underline" @click="showMore = !showMore">
                {{ showMore ? $t("cookie_consent_popup.view_less") : $t("cookie_consent_popup.view_more") }}
              </div>
            </template>
          </device-wrapper>
        </div>
        <div class="flex items-center w-full md:max-w-[160px] text-white">
          <van-button block class="!rounded-md" type="primary" @click="onHide">
            <template #icon>
              <van-icon :name="$publicPath('/images/check.svg')" class="me-2" size="22"/>
            </template>
            {{ $t('cookie_consent_popup.accept') }}
          </van-button>
        </div>
      </div>
    </div>
  </van-popup>
</template>

<script setup>
import {COOKIE_KEYS} from "@/constants/cookie";
import {useContentStore} from "@/store/content";
import {ONE_YEAR_IN_SECONDS} from "@/constants";

const contentStore = useContentStore()
const {isCookieBannerShown} = storeToRefs(contentStore)

const showMore = ref(false)

const onHide = () => useCookie(COOKIE_KEYS.COOKIE_CONSENT, {maxAge: ONE_YEAR_IN_SECONDS}).value = true
</script>

<style lang="scss" scoped>
</style>
<template>
  <div v-if="!hideTabbar"
       class="tabbar fixed bottom-0 left-0 right-0 grid grid-cols-5 gap-2 bg-white h-[56px] w-full shadow z-1 border-t">
    <nuxt-link :to="$routerUrl('/')" class="flex flex-col items-center justify-center">
      <van-icon :alt="$t('menu.home')"
                :name="$publicPath(`/images/tabbar/${isActiveTab === 'index' ? 'home-active' : 'home'}.svg`)"
                size="24"/>
      <span :class="isActiveTab === 'index' ? 'text-color-[#000]' : 'text-color-[#0000008a]'"
            class="text-[10px] mt-1">{{ $t('menu.home') }}</span>
    </nuxt-link>
    <nuxt-link :to="$routerUrl('/categories')" class="flex flex-col items-center justify-center">
      <van-icon
          :alt="$t('menu.categories')"
          :name="$publicPath(`/images/tabbar/${isActiveTab === 'categories' ? 'category-active' : 'category'}.svg`)"
          size="24"/>
      <span :class="isActiveTab === 'categories' ? 'text-color-[#000]' : 'text-color-[#0000008a]'"
            class="text-[10px] mt-1">{{ $t('menu.categories') }}</span>
    </nuxt-link>
    <nuxt-link :to="$routerUrl('/brands')" class="flex flex-col items-center justify-center">
      <van-icon :alt="$t('menu.brands')"
                :name="$publicPath(`/images/tabbar/${isActiveTab === 'brands' ? 'brands-active' : 'brands'}.svg`)"
                size="24"/>
      <span :class="isActiveTab === 'brands' ? 'text-color-[#000]' : 'text-color-[#0000008a]'"
            class="text-[10px] mt-1">{{ $t('menu.brands') }}</span>
    </nuxt-link>
    <nuxt-link :to="$routerUrl('/cart')" class="cart relative flex flex-col items-center justify-center">
      <van-badge :content="count" :max="99" :position="$i18n.locale === 'en' ? 'top-right' : 'top-left'"
                 :show-zero="false">
        <van-icon :alt="$t('menu.cart')"
                  :name="$publicPath(`/images/tabbar/${isActiveTab === 'cart' ? 'cart-active' : 'cart'}.svg`)"
                  size="24"/>
      </van-badge>
      <span :class="isActiveTab === 'cart' ? 'text-color-[#000]' : 'text-color-[#0000008a]'"
            class="text-[10px] mt-1">{{ $t('menu.bag') }}</span>
    </nuxt-link>
    <nuxt-link :to="$routerUrl('/account')" class="flex flex-col items-center justify-center">
      <van-icon :alt="$t('menu.account')"
                :name="$publicPath(`/images/tabbar/${isActiveTab === 'account' ? 'account-active' : 'account'}.svg`)"
                size="24"/>
      <span :class="isActiveTab === 'account' ? 'text-color-[#000]' : 'text-color-[#0000008a]'"
            class="text-[10px] mt-1">{{ $t('menu.account') }}</span>
    </nuxt-link>
  </div>
</template>

<script setup>
import {isAppView} from "@/utilities";
import {useCartStore} from '@/store/cart'
import {useRouteMatcher} from "~/composables/useRouteMatcher";
import {PAGES_NAMES, TABBAR_NOT_ALLOWED_ROUTES} from "@/constants/pages";

const route = useRoute()
const isActiveTab = computed(() => route?.name?.split('_')?.[0] || PAGES_NAMES.HOME)

const {isRouteMatched} = useRouteMatcher();

const hideTabbar = computed(() => useNuxtApp().$device.isDesktop || isAppView.value || isRouteMatched(TABBAR_NOT_ALLOWED_ROUTES))

const cartStore = useCartStore()
const {count} = storeToRefs(cartStore)
</script>

<style>
.cart .van-badge__wrapper .van-icon {
  display: initial !important;
}

/*.tabbar {*/
/*  padding-bottom: env(safe-area-inset-bottom);*/
//}
</style>
<template>
  <van-popup v-model:show="show"
             :position="$i18n.locale === 'en' ? 'right' : 'left'"
             :style="{ width: !$device.isDesktop ? '100%' : '500px', height: '100%' }" @closed="onClose">
    <verify-base-layout v-model="code" v-model:timer="timer" :loading="isLoading" :number="number"
                        :resend-loading="resendLoading" :title="$t('verify.verify')"
                        @close="onClose" @resend="resendSubmit" @verify="verifySubmit"/>
  </van-popup>
</template>

<script setup>
import {track} from "@/composables/useTrackEvent";
import {EVENTS, TRACKERS} from "@/constants/events"
import {useEventBus} from '@vueuse/core'
import {resendVerificationMutation, verify} from '@/plugins/API/auth';
import {useAuthStore} from '@/store/auth'
import VerifyBaseLayout from "@/components/VerifyBaseLayout";
import {COOKIE_KEYS} from "@/constants/cookie";
import {setAndFetchUser} from "@/utilities";
import {ONE_YEAR_IN_SECONDS} from "@/constants";

const authStore = useAuthStore()

const show = ref(false)
const number = ref('')
const code = ref('')
const timer = ref(0)
const customerId = ref('')

const {on} = useEventBus(EVENTS.VERIFY_DIALOG)

const isRegister = ref('')

on(({phone, isRegister: cbIsRegister = false, customer_id = ''}) => {
  show.value = true
  number.value = phone
  timer.value = 60 * 1000
  customerId.value = customer_id
  isRegister.value = cbIsRegister
  track(TRACKERS.OPEN_OTP_VERIFICATION_PAGE)
});

const onClose = () => {
  show.value = false
  number.value = ''
  timer.value = 0
  code.value = ''
}

const router = useRouter()
const token = useCookie(COOKIE_KEYS.USER_TOKEN, {
  maxAge: ONE_YEAR_IN_SECONDS
})

const {isLoading, mutateAsync} = verify()
const verifySubmit = async () => {

  const gcToken = await useNuxtApp().$recaptcha.execute('REGISTRATION_SUBMIT_OTP')

  track(TRACKERS.SUBMIT_OTP_CODE)
  mutateAsync({code: code.value, isRegister: isRegister.value, gc_token: gcToken}).then(({data}) => {
    token.value = data?.authToken
    setAndFetchUser(data)

    track(TRACKERS.OTP_SUCCESS)
    show.value = false
    useNuxtApp().$redirectAfterLogin()
  })
}

const {isLoading: resendLoading, mutateAsync: resendMutateAsync} = resendVerificationMutation()
const resendSubmit = async () => {
  const gcToken = await useNuxtApp().$recaptcha.execute('REGISTRATION_RESEND_OTP')
  resendMutateAsync({isRegister: isRegister.value, gc_token: gcToken, customer_id: customerId.value}).then(() => {
    timer.value = 60 * 1000
  })
}
</script>

<style>
.otp-field .van-field__control {
  text-align: center !important;
}
</style>
import {COOKIE_KEYS} from "~/constants/cookie";
import {isAppView} from "~/utilities";
import {useAuthStore} from "~/store/auth";
import {SMART_BANNER_ALLOWED_ROUTES} from "~/constants/pages";
import {useRouteMatcher} from "~/composables/useRouteMatcher";

export const useContentStore = defineStore('content', () => {

    // Initial Popup
    const isPopupLoaded = ref(false)
    const content = ref([])
    const setContent = (cbContent) => {
        content.value = cbContent
    }

    // Smart banner Popup for mobile apps
    const authStore = useAuthStore();
    const {isLoggedIn, loading} = storeToRefs(authStore)

    const {isRouteMatched} = useRouteMatcher();

    const smartBannerUseCookie = computed(() => useCookie(COOKIE_KEYS.SMART_BANNER).value === undefined);
    const isSmartBannerShown = computed(() => {
        return (!useNuxtApp().$device.isDesktop && !isLoggedIn.value && smartBannerUseCookie.value && !loading.value && !isAppView.value && isRouteMatched(SMART_BANNER_ALLOWED_ROUTES))
    })

    // Cookie consent banner popup
    const cookieConsentUseCookie = useCookie(COOKIE_KEYS.COOKIE_CONSENT)
    const isCookieBannerShown = computed(() => !isLoggedIn.value && !cookieConsentUseCookie.value && !isAppView.value)

    return {
        content,
        isPopupLoaded,
        setContent,
        isSmartBannerShown,
        isCookieBannerShown
    }
})
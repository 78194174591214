export const PAGES_NAMES = {
    HOME: 'index',
    CATEGORY: 'slugs',
    PRODUCT: 'product-details',
    LOGIN: 'login',
    REGISTER: 'register',
    NOTIFICATIONS: 'notifications',
    CHECKOUT: 'checkout',
    CHECKOUT_ADDRESS_LIST: 'checkout-address-list',
    CHECKOUT_ADDRESS_DETAILS: 'checkout-address-id',
    TRACK: 'track',
    BLOGS: 'blogs',
    BLOG_DETAILS: 'blogs-slug',
    GIFT_PROD: 'gift-test-id',
    GIFT_TEST: 'gift-test-id',
    FEEDBACK: 'feedback-id',
    FEEDBACK_DETAILS: 'feedback-id',
    CONFIRM_ACCOUNT_DELETE: 'confirm-account-delete-id',
    INVOICE_DETAILS: 'invoice-id',
    BRANDS: 'brands'
}

export const TABBAR_NOT_ALLOWED_ROUTES = [PAGES_NAMES.LOGIN, PAGES_NAMES.REGISTER, PAGES_NAMES.INVOICE_DETAILS, PAGES_NAMES.NOTIFICATIONS, PAGES_NAMES.CHECKOUT, PAGES_NAMES.CHECKOUT_ADDRESS_LIST, PAGES_NAMES.CHECKOUT_ADDRESS_DETAILS, PAGES_NAMES.GIFT_TEST, PAGES_NAMES.GIFT_PROD, PAGES_NAMES.FEEDBACK, PAGES_NAMES.FEEDBACK_DETAILS, PAGES_NAMES.CONFIRM_ACCOUNT_DELETE, PAGES_NAMES.TRACK, PAGES_NAMES.BLOGS, PAGES_NAMES.BLOG_DETAILS]

export const SMART_BANNER_ALLOWED_ROUTES = [PAGES_NAMES.HOME, PAGES_NAMES.CATEGORY, PAGES_NAMES.PRODUCT]
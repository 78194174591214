<template>
  <nuxt-link :to="$routerUrl(getProductLink)" class="w-full relative" v-bind="$attrs">
    <div
        v-if="!isEmpty(product.cross_discount)"
        class="flex items-center bg-white px-3 pt-2"
    >
      <img :alt="product?.cross_discount?.text" :src="$publicPath('/images/discount-badge.svg')">
      <div class="ms-2 text-error text-sm">
        {{ product?.cross_discount?.text }}
      </div>
    </div>
    <div :class="{'border-b': !isLastItem}" class="flex items-center bg-white p-3 w-full">

      <!-- Out of stock overlay -->
      <div v-if="!product.stock && !hideOutOfStock"
           class="absolute bg-white bg-opacity-50 z-1 left-0 right-0 top-0 bottom-0"></div>

      <div class="w-[80px] flex-[0_0_auto]">
        <niceone-image :src="product.thumb" class="flex-center py-2 ltr:pr-2 rtl:pl-2"></niceone-image>
      </div>
      <div class="w-full">
        <div class="flex justify-between w-full">
          <div>
            <div class="text-xs text-grey-muted mb-1">{{ product.manufacturer_name }}</div>
            <div class="van-multi-ellipsis--l2 text-sm mb-1">{{ product.name }}</div>
            <div
                v-if="product?.option?.length"
                class="text-grey-muted text-xs"
            >
              {{ product.option[0].name }}: {{ product.option[0].value }}
            </div>
          </div>
          <slot name="remove">
            <remove v-if="!hideDelete" :loading="removeLoading" :product="product" class="z-1" @remove="onRemove"/>
          </slot>
        </div>
        <div class="flex justify-between items-end">
          <slot name="price">
            <price :min-height="30" :product="product" price-key="price_formatted"></price>
          </slot>
          <slot name="counter">
            <counter v-if="!hideCounter" :loading="updateLoading" :product="product" @remove="onRemove"
                     @update="onCounterUpdate"/>
          </slot>
        </div>
        <slot name="AddReview"/>
      </div>
    </div>
  </nuxt-link>
  <slot>
    <div v-if="!product.stock && !hideOutOfStock"
         class="flex-center font-niceone-medium text-xs pb-1 text-error bg-lava-light">
      {{ $t('product.out_of_stock') }}
    </div>
  </slot>
</template>

<script setup>
import Price from "@/components/product/Price"
import Counter from "@/components/cart/Counter"
import Remove from "@/components/cart/Remove"
import {removeCartQuery, updateCartQuery} from "@/plugins/API/cart";
import {useIsEmpty} from "@/composables/useIsEmpty";
import {EVENTS, TRACKERS} from "@/constants/events";
import {track} from "@/composables/useTrackEvent";
import {useEventBus} from "@vueuse/core";

const {isEmpty} = useIsEmpty()

const props = defineProps({
  product: {
    type: Object,
    default: () => {
    },
  },
  index: {
    type: Number,
    default: 0
  },
  hideDelete: {
    type: Boolean,
    default: false
  },
  hideCounter: {
    type: Boolean,
    default: false
  },
  hideOutOfStock: {
    type: Boolean,
    default: false
  },
  isLastItem: {
    type: Boolean,
    default: false
  },
  nameKey: {
    type: String,
    default: 'en_name'
  }
})

const getProductLink = computed(() => {

  // Use SEO Url from the backend
  if (props.product?.seo_url) return useNuxtApp().$routerUrl(props.product?.seo_url)

  // Otherwise generate seo url
  const productLink = `${props.product?.[props.nameKey]?.replaceAll('-', '').toLowerCase().split(' ').join('-')}-n${props.product.product_id}`
  return !!props.product?.option?.length ? `${productLink}-${props.product?.option[0]?.product_option_variant_id}` : productLink
})

const {emit: emitCartUpdated} = useEventBus(EVENTS.CART_UPDATED);

const {isLoading: removeLoading, mutateAsync: removeMutateAsync} = removeCartQuery()
const onRemove = (params) => {
  removeMutateAsync(params).then(() => {
    emitCartUpdated()
    track(TRACKERS.REMOVE_FROM_CART, {product: props.product, index: props.index})
  })
}

const {isLoading: updateLoading, mutateAsync: updateMutateAsync} = updateCartQuery()
const onCounterUpdate = (params) => {
  updateMutateAsync(params).then(() => {
    emitCartUpdated()
    track(TRACKERS.INCREMENT_CART, {product: props.product, index: props.index})
  })
}
</script>
<template>
  <auth-layout
      :loading="isLoading"
      :submi-buttont-name="$t('auth.login')"
      @submit="onLogin"
  >
    <template #title>{{ $t('auth.hello_there_login') }}</template>

    <div class="rounded-md bg-white">
      <niceone-field
          v-model="email"
          :placeholder="$t('auth.email')"
          class="rounded-md"
          type="email"
      ></niceone-field>
      <password-field v-model="password"
                      :placeholder="$t('auth.password')" class="rounded-md" icon-type="icon">
        <template #end-button>
          <div
              class="cursor-pointer text-primary"
              @click="onClickForgot"
          >
            {{ $t('auth.forgot') }}
          </div>
        </template>
      </password-field>
    </div>

    <template #actions>
      <nuxt-link
          class="flex cursor-pointer items-center mb-3"
          @click="authStore.changeType(AUTH_TYPES.LOGIN_PHONE)"
      >
        <van-icon :name="$publicPath('/images/auth/phone.svg')" size="20"/>
        <div class="mx-2">{{ $t('auth.login_using_your_phone_number') }}</div>
      </nuxt-link>
      <nuxt-link
          class="flex cursor-pointer items-center mb-3"
          @click="onClickRegister"
      >
        <van-icon :name="$publicPath('/images/auth/profile.svg')" size="20"/>
        <div class="mx-2">{{ $t('auth.create_new_account') }}</div>
      </nuxt-link>
    </template>
  </auth-layout>
</template>

<script setup>
import AuthLayout from "@/components/AuthLayout";
import {loginEmail} from "@/plugins/API/auth";
import {AUTH_TYPES, ONE_YEAR_IN_SECONDS} from "@/constants";
import {useAuthStore} from "@/store/auth";
import PasswordField from "@/components/form/PasswordField";
import {track} from "@/composables/useTrackEvent";
import {TRACKERS} from "@/constants/events";
import {COOKIE_KEYS} from "@/constants/cookie";
import {setAndFetchUser} from "@/utilities";

const authStore = useAuthStore();

const email = ref("");
const password = ref("");

const token = useCookie(COOKIE_KEYS.USER_TOKEN, {
  maxAge: ONE_YEAR_IN_SECONDS
});

const router = useRouter();

const {isLoading, mutateAsync} = loginEmail();
const onLogin = () => {
  mutateAsync({email: email.value, password: password.value})
      .then(({data}) => {
            token.value = data?.authToken;
            setAndFetchUser(data)

            track(TRACKERS.LOGIN_SUCCESS, 'Email');
            useNuxtApp().$redirectAfterLogin()
          }
      ).catch((error) => {
    track(TRACKERS.LOGIN_FAIL, error)
  });
};
const onClickRegister = () => {
  authStore.changeType(AUTH_TYPES.REGISTER)
  track(TRACKERS.REGISTER_INITIATE)
}
const onClickForgot = () => {
  authStore.changeType(AUTH_TYPES.FORGOT)
  track(TRACKERS.FORGOT_PASSWORD)
}
</script>

export default () => {

    const {t} = useI18n()

    const i18nHead = useLocaleHead({
        addSeoAttributes: {
            canonicalQueries: ['q', 'page', 'filters', 'manufacturers', 'sort']
        }
    })

    const currentYear = new Date().getFullYear()

    const {getCommonMeta} = useSeoMeta()

    return {
        titleTemplate: (title) => `${title} | ${t('seo.niceone_ksa')}`,
        title: t('seo.title'),
        htmlAttrs: {
            lang: i18nHead.value.htmlAttrs?.lang,
            dir: i18nHead.value.htmlAttrs?.lang === 'ar' ? 'rtl' : 'ltr'
        },
        meta: [
            {charset: 'utf-8'},
            {name: 'viewport', content: 'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0'},
            {name: 'apple-itunes-app', content: 'app-id=1271754138'},
            ...getCommonMeta(),
            {
                name: 'rating',
                content: 'General'
            },
            {
                name: 'Classification',
                content: 'Business'
            },
            {
                name: 'google-site-verification',
                content: 'n4PA0RbS_xQzTPuk_EdqjaSAX2aZQ-2TtrbOJPkH-Tw'
            },
            {
                name: 'copyright',
                content: `Copyright © 2016 – ${currentYear} NICE ONE || all right reserved`
            },
            ...(i18nHead.value.meta || [])
        ]
    }
}
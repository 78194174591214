<template>
  <div class="flex items-center gap-1 h-[48px] text-black text-sm">
      <span>
        <niceone-image
            :alt="countryFlag?.name"
            :src="countryFlag?.thumb"
            width="18"
        />
      </span>
    <span><van-icon name="arrow-down" size="16"></van-icon></span>
  </div>
  <div
      class="absolute block bg-[#f9f9f9] ltr:right-0 rtl:left-0 me-2 shadow-cus p-[25px] top-[100%] w-[360px] z-10 border-t-2 border-black rounded-b-md menu-niceone"
  >
    <p class="text-xs mb-5 font-semibold text-txt-secondary">{{ $t('menu.country') }}</p>
    <ul class="flex flex-col space-y-5">
      <li>
        <van-radio-group v-model="countryValue" class="flex flex-col gap-4">
          <van-radio
              v-for="(item, index) in countries"
              :key="index"
              :name="item.iso_code_2"
              @click="update(item)"
          >{{ item.name }}
          </van-radio>
        </van-radio-group>
      </li>
    </ul>
  </div>
</template>

<script setup>
import {useCountryStore} from "@/store/countries";
import {useCurrencyStore} from '@/store/currency'
import {storeToRefs} from "pinia";
import {CURRENCY_CODE_BY_ISO, ISO_CODE_BY_CURRENCY} from "@/helper";
import {track} from "~/composables/useTrackEvent";
import {TRACKERS} from "~/constants/events";

const {locale} = useI18n()
const currencyStore = useCurrencyStore()
const {currency} = storeToRefs(currencyStore)
const {countries} = useCountryStore();
const countryValue = ref(ISO_CODE_BY_CURRENCY[currency.value])
const languageValue = ref(locale.value)
const router = useRouter()
const {setLocale} = useI18n()
const switchLocalePath = useSwitchLocalePath()
const countryFlag = computed(() => {
  return countries?.find((item) => item.iso_code_2 === countryValue.value);
});
const update = (item) => {
  setLocale(languageValue.value)
  switchLocalePath(languageValue.value)
  track(TRACKERS.NAVIGATION_MENU, {
    eventLabel: 'Country',
    eventAction: item.name
  });
  currency.value = CURRENCY_CODE_BY_ISO[item.iso_code_2]
  window.location.reload()
}
</script>

<style lang="scss" scoped></style>